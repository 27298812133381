// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link.btn { 
    border-radius: 5px;  /* Adjust border radius if needed */
    padding: 0.5rem 1rem; /* Adjust padding if needed */
    border: 2px solid orange; /* Set a solid orange border */
    color: orange; /* Example: make the text black */
  }

  .nav-link.btn:hover { 
    border-radius: 5px;  /* Adjust border radius if needed */
    padding: 0.5rem 1rem; /* Adjust padding if needed */
    border: 2px solid orange; /* Set a solid orange border */
    color: orange; /* Example: make the text black */
  }


.nav-link {
    font-family: 'Britanica-Bold';
}`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,GAAG,mCAAmC;IACxD,oBAAoB,EAAE,6BAA6B;IACnD,wBAAwB,EAAE,8BAA8B;IACxD,aAAa,EAAE,iCAAiC;EAClD;;EAEA;IACE,kBAAkB,GAAG,mCAAmC;IACxD,oBAAoB,EAAE,6BAA6B;IACnD,wBAAwB,EAAE,8BAA8B;IACxD,aAAa,EAAE,iCAAiC;EAClD;;;AAGF;IACI,6BAA6B;AACjC","sourcesContent":[".nav-link.btn { \n    border-radius: 5px;  /* Adjust border radius if needed */\n    padding: 0.5rem 1rem; /* Adjust padding if needed */\n    border: 2px solid orange; /* Set a solid orange border */\n    color: orange; /* Example: make the text black */\n  }\n\n  .nav-link.btn:hover { \n    border-radius: 5px;  /* Adjust border radius if needed */\n    padding: 0.5rem 1rem; /* Adjust padding if needed */\n    border: 2px solid orange; /* Set a solid orange border */\n    color: orange; /* Example: make the text black */\n  }\n\n\n.nav-link {\n    font-family: 'Britanica-Bold';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
