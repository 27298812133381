import React from 'react'
import '../styles/Sponsors.css'
export default function Sponsors() {
  return (
    <div className='sponsorships'>
    <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 className='mt-3 text-light sponsor-title'>Trusted by over 100+ companies</h1>
      </div>
    </div>
    <div class="row text-center mt-4 justify-content-center">
      <div class="col-md-2">
        <img src="./sponsor-example.png" alt="Company logo" class="img-fluid" />
      </div>
      <div class="col-md-2">
        <img src="./sponsor-example.png" alt="Company logo" class="img-fluid" />
      </div>
      <div class="col-md-2">
        <img src="./sponsor-example.png" alt="Company logo" class="img-fluid" />
      </div>
      <div class="col-md-2">
        <img src="./sponsor-example.png" alt="Company logo" class="img-fluid" />
      </div>
      <div class="col-md-2">
        <img src="./sponsor-example.png" alt="Company logo" class="img-fluid" />
      </div>
    </div>
  </div>
  </div>
  
  
  )
}
