import React from 'react'
import '../styles/Navbar.css'
export default function Navbar() {
  return (
<nav class="navbar navbar-expand-lg bg-body-black p-4 sticky-top" style={{ backgroundColor: 'black' }} id='navbar-example1'>
  <div class="container-fluid">
    <a class="navbar-brand px-4" href="#">
        <img src= "./logo.png" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item mx-2 px-2">
          <a class="nav-link text-white fs-4" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item mx-2 px-2">
          <a class="nav-link text-white fs-4" href="#services">Services</a>
        </li>
        <li class="nav-item mx-2 px-2">
          <a class="nav-link text-white fs-4" href="#about-us">About Us</a>
        </li>
        <li class="nav-item mx-2 px-2">
          <a class="nav-link text-orange btn fs-5" href="#contact-us">Contact Us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
  )
}
