import React from 'react'
import '../../styles/ServicesCard.css'
import { MdOutlineDesignServices } from "react-icons/md";

export default function ServicesCard(props) {
  return (
    <div className="services-card">
    <h3 className='services-card-title'>{props.title}</h3>
    <p className="services-card-desc">{props.desc}</p>
    </div>
  
  )
}
