import React from 'react'
import '../styles/Services.css'
import ServicesCard from './shared/ServicesCard'

export default function Services() {
  return (
    <div className='services-container'>
      <h1 className='services-title'>Services</h1>
      <div className='services-card-container'>
      <ServicesCard title='ASIC/FPGA Advanced Verification' desc='We leverage state-of-the-art verification methodologies including SystemVerilog/UVM, UVVM and formal verification to comprehensively validate your ASIC/FPGA designs.' />
      <ServicesCard title='ASIC/FPGA Design' desc='At Aquilla Design and Verification Center, we specialize in delivering cutting-edge ASIC/FGPA design solutions tailored to meet the unique requirements of your digital projects.' />
      <ServicesCard title='Training Services' desc='We offer comprehensive training programs designed to empower engineers with the skills and knowledge needed to excel in SystemVerilog and UVM.' />
      </div>
      
    </div>
  )
}
