import React from 'react'
import '../../styles/ContactCardInfo.css'
import { MdEmail, MdEmojiEmotions } from "react-icons/md";

export default function ContactCard(props) {
  return (
    <div className='contact-card-info'>
       {props.icon}
     <h1> <a href={props.href}>{props.name}</a> </h1>
    </div>
  )
}
