// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-card-info {
    display: inline-flex;
    flex-direction: row;
    gap: 24px;
}

.contact-card-info-icon {
    color: #F27B22;
    font-size: 3rem;
}

.contact-card-info a {
    color: #F27B22;   
}`, "",{"version":3,"sources":["webpack://./src/styles/ContactCardInfo.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".contact-card-info {\n    display: inline-flex;\n    flex-direction: row;\n    gap: 24px;\n}\n\n.contact-card-info-icon {\n    color: #F27B22;\n    font-size: 3rem;\n}\n\n.contact-card-info a {\n    color: #F27B22;   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
