// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/hero-section.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Replace with the actual path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    align-items: center;
  }


  .content {
    display: inline;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    margin-bottom: 10%;
  }

  .content h1 {
    font-family: 'Britanica-Bold';
    font-size: 6rem;
   }
`, "",{"version":3,"sources":["webpack://./src/styles/HeroSection.css"],"names":[],"mappings":"AAAA;IACI,yDAAsD,EAAE,+CAA+C;IACvG,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;;EAGA;IACE,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,6BAA6B;IAC7B,eAAe;GAChB","sourcesContent":[".background-image {\n    background-image: url('../../public/hero-section.png'); /* Replace with the actual path to your image */\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    height: 70vh;\n    display: flex;\n    align-items: center;\n  }\n\n\n  .content {\n    display: inline;\n    flex-direction: column;\n    justify-content: start;\n    text-align: start;\n    margin-bottom: 10%;\n  }\n\n  .content h1 {\n    font-family: 'Britanica-Bold';\n    font-size: 6rem;\n   }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
