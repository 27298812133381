import React from 'react'
import '../styles/ContactUs.css'
import ContactCard from './shared/ContactCard'
import { MdEmail, MdLocalPhone } from "react-icons/md";

export default function ContactUs() {
  return (
    <div className='contact-us-container' id='contact-us'>
    <div className="contact-us-card">
      <div className="contact-us-text-section">
          <h1 className="contact-us-text-title">Contact Us</h1>
          <p className="contact-us-text-desc">Whether you're looking for ASIC/FPGA design and verification services, expert training in SystemVerilog and UVM, or both, we're here to help. Contact us today to learn more about how we can support your projects and empower your team to achieve their full potential.</p>
          <ContactCard icon={<MdEmail className='contact-card-info-icon' />} name='jorganxhiu.ahmet@aquilladvcenter.com' href='mailto:jorganxhiu.ahmet@aquilladvcenter.com' />
          <br />
          <ContactCard icon={<MdLocalPhone className='contact-card-info-icon' />} name='+383 49 470 018' href='tel:+38349470018' />
      </div>
    </div>
  </div>
  )
}
