import React from 'react'
import '../styles/AboutUs.css'

export default function AboutUs() {
  return (
    <div className='about-us-container' id='about-us'>
    <div className="about-us-text-section">
      <h1 className='about-us-text-title'>About Us</h1>
      <p className='about-us-text-desc'>At Aquilla Design and Verification Center, our mission is to empower individuals and organizations with the knowledge, skills, and tools they need to succeed in the fast-paced world of ASIC/FPGA design and verification. We are commited to fostering innovation, excellence, and continous learning in the semiconductor industry. We provide end-to-end solutions for ASIC/FPGA design and verification, leveraging cutting-edge technologies and best-in-class methodologies to deliver high-quality, reliable, and scalable designs. From concept to tape-out, we partner with our clients to turn their ideas into reality, helping them achieve their designs goals efficiently and effectively. n addition to our design and verification services, we offer comprehensive training programs in SystemVerilog and UVM, equipping engineers with the skills and knowledge they need to excel in advanced verification methodologies. Our training courses combine theoretical concepts with practical hands-on excercises, delivered by industry experts with years of experience in ASIC/FPGA design and verification.</p>

    </div>
    <div className="about-us-image-section">
      <img src={'./about-us-image.png'} className='about-us-image' />
    </div>
  </div>
)
}
