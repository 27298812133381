import React from 'react'
import '../styles/HeroSection.css'

export default function HeroSection() {
  return (
   <div className='background-image' id='home'>
    <div className="container-fluid">
        <div className="content">
            <h1 className="text-light m-5">Smart Concept <br /> For Better <br /> Engineering</h1>
            <br /> <br />
        </div>
    </div>
</div>
)
}
