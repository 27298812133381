import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Sponsors from './components/Sponsors';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css'

function App() {
  return (
    <div className="App" data-spy='scroll' data-target='#navbar-example1' data-offset='-50'>
      <Navbar />
      <HeroSection />
      <Services />
      <AboutUs />
      <ContactUs />
    </div>
  );
}

export default App;
