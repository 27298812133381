// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sponsorships {
  background-color: #015958;
  height: 22vh;
}

.sponsor-title {
  font-family: 'Britanica-Bold';
}
  `, "",{"version":3,"sources":["webpack://./src/styles/Sponsors.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".sponsorships {\n  background-color: #015958;\n  height: 22vh;\n}\n\n.sponsor-title {\n  font-family: 'Britanica-Bold';\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
